import { Navbar } from '__generated__/schema.graphql.types';
import Link from 'next/link';
import { CSSProperties, FC } from 'react';
import css from 'styles/Logo.module.scss';
import cn from 'classnames';
import StrapiImage from 'components/shared/Image';

interface Props {
  logo: Navbar['logo'];
  heightModifier?: number;
  className?: string;
  style?: CSSProperties;
}

const Logo: FC<Props> = ({ logo, heightModifier, className, style }) => {
  const height = logo.data?.attributes?.height;
  const width = logo.data?.attributes?.width;

  let newWidth = 0;
  let newHeight = 0;
  if (typeof height !== 'undefined' && typeof width !== 'undefined') {
    if (height !== null && width !== null) {
      const aspect = width / height;
      newHeight = height / (heightModifier ?? 3.75);
      newWidth = newHeight * aspect;
    }
  }

  return (
    <Link href="/" className={cn(css.logoContainer, className)}>
      <StrapiImage
        strapi={logo}
        height={newHeight}
        width={newWidth}
        style={{
          maxWidth: '100%',
          height: 'auto',
          ...style
        }}
      />
    </Link>
  );
};

export default Logo;

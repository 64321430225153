'use client'

import css from 'styles/Footer.module.scss'
import StrapiImage from 'components/shared/Image';
import { FC } from 'react'
import { ComponentElementsLink, ComponentNavProduct, ComponentNavProductVariant, Footer as FooterType, FooterSectionItemsDynamicZone } from '__generated__/schema.graphql.types';
import { MappedDynamicZone } from 'types/dynamiczone';
import Link from 'next/link';

const FooterSectionLink: FC<ComponentElementsLink> = ({ label, url }) => {
  return (
    <li>
      <Link href={url ?? ''}>
        {label}
      </Link>
    </li>
  )
}

const FooterSectionProduct: FC<ComponentNavProduct> = ({ product }) => {
  const attributes = product?.data?.attributes
  const page = attributes?.page
  const name = attributes?.name

  return (
    <li>
      <Link href={'/' + page?.data?.attributes?.slug}>
        {name}
      </Link>
    </li>
  )
}

const FooterSectionProductVariant: FC<ComponentNavProductVariant> = ({ productVariant }) => {
  const attributes = productVariant?.data?.attributes
  const product = attributes?.product
  const name = attributes?.name
  const page = product?.data?.attributes?.page

  return (
    <li>
      <Link href={'/' + page?.data?.attributes?.slug}>
        {name}
      </Link>
    </li>
  )
}

const sectionItemsMap: MappedDynamicZone<FooterSectionItemsDynamicZone> = {
  ComponentElementsLink: FooterSectionLink,
  ComponentNavProduct: FooterSectionProduct,
  ComponentNavProductVariant: FooterSectionProductVariant,
}


const Footer: FC<FooterType> = ({ logo, body, sections }) => {
  const attributes = logo?.data?.attributes
  const width = attributes?.width ?? 0
  const height = attributes?.height ?? 1

  const aspect = width / height
  const newHeight = height / 3
  const newWidth = newHeight * aspect
  const currentYear = new Date().getFullYear()

  return (
    <footer className={css.footer}>
      <div>
        <aside>
          <div className={css.logoContainer}>
            {logo && (
              <StrapiImage
                strapi={logo}
                sizes="100vw"
                height={newHeight}
                width={newWidth}
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }}
              />
            )}
          </div>
          <p>{body}</p>
        </aside>
        <div className={css.sections}>
          {sections?.data.map(data => (
            <section key={data.id}>
              <h4>{data.attributes?.title}</h4>
              <ul>
                {data.attributes?.items?.map(item => {
                  if (item?.__typename === 'Error') { return }
                  if (typeof item?.__typename === 'undefined') { return }
                  const Component = sectionItemsMap[item.__typename]

                  return (
                    // @ts-expect-error
                    <Component key={item.id} {...item} />
                  )
                })}
              </ul>
            </section>
            ))}
        </div>
      </div>
      <div className={css.copyrightWrapper}>
        <p className={css.copyright}>
          <span className={css.materialIcons}/>
          <style jsx>{`
            span:after {
              content: 'copyright';
            }
          `}</style>
          {currentYear} Ettevõtlusküla
        </p>
      </div>
    </footer>
  );
}

export default Footer

'use client';

import Logo from 'components/shared/nav/Logo';
import Link from 'next/link';
import StrapiImage from 'components/shared/Image';

import { CSSProperties, FC, MouseEventHandler, useMemo, useState } from 'react';
import css from 'styles/SmallMenu.module.scss';
import cn from 'classnames';

import {
  ComponentElementsLink,
  ComponentNavNavSection,
  ComponentNavProduct,
  ComponentNavProductMenu,
  Navbar,
  NavbarItemsDynamicZone,
  ProductCollectionEntity,
} from '__generated__/schema.graphql.types';
import { MappedDynamicZone } from 'types/dynamiczone';
import Color from 'color';

const NavLink: FC<ComponentElementsLink> = ({ url, label, id }) => {
  return (
    <li className={css.link} key={id}>
      <Link href={url ?? ''}>
        <span>{label}</span>
        <span className={css.materialIcons} />
      </Link>
    </li>
  );
};

interface SmallProductMenuProps extends ComponentNavProductMenu {
  selectSection: (section: ComponentNavNavSection | null) => void;
  accentColor: string;
}

const SmallProductMenu: FC<SmallProductMenuProps> = ({
  label,
  sections,
  id,
  selectSection,
  accentColor,
}) => {
  const color = useMemo(() => Color(accentColor), [accentColor]);
  const linearGradient = `linear-gradient(
          90deg,
          ${color.alpha(0.08).rgb().string()} 0%,
          ${color.alpha(0).rgb().string()}  100%
        )`;

  return (
    <li
      key={id}
      className={css.smallProductMenu}
      style={{ backgroundColor: color.alpha(0.08).rgb().string() }}
    >
      <h3 style={{ color: color.hex() }}>{label}</h3>
      <ul className={css.sections}>
        {sections?.map((section) => {
          const handleSectionClick = () => {
            selectSection(section);
          };

          return (
            <li key={section?.id}>
              <button onClick={handleSectionClick} style={{ '--linearGradient': linearGradient } as CSSProperties}>
                <h4>{section?.title}</h4>
                <p>{section?.description}</p>
                <span className={css.materialIcons} />
                <style jsx>{`
                  span:after {
                    content: 'navigate_next';
                    color: ${color.alpha(0.75).rgb().string()};
                  }
                `}</style>
              </button>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

const navbarItemsMap: MappedDynamicZone<NavbarItemsDynamicZone> | any = {
  ComponentElementsLink: NavLink,
  ComponentNavProductMenu: SmallProductMenu,
};

interface ProductProps extends ComponentNavProduct {}

const Product: FC<ProductProps> = ({ product }) => {
  const attributes = product!.data?.attributes;

  const page = attributes?.page;
  const icon = attributes?.icon;
  const color = attributes?.accentColor;
  const description = attributes?.description;
  const name = attributes?.name;
  const duration = attributes?.duration
  const ageRange = attributes?.ageRange

  return (
    <li className={cn(css.product)} key={product!.data?.id}>
      <Link href={'/' + page?.data?.attributes?.slug}>
        <div className={css.iconContainer}>
          {icon && <StrapiImage strapi={icon} fill sizes="100vw" />}
        </div>
        <h4 className={css.title} style={{ color: color ?? '' }}>{name}</h4>
        <div className={css.description}>
          <p>{description}</p>
          <div className={css.chips}>
            {[ageRange, duration].map(chip => {
              return chip && <span key={chip} className={css.chip}>{chip}</span>
            })}
          </div>
        </div>
      </Link>
    </li>
  );
};

const Collection: FC<ProductCollectionEntity> = ({ attributes }) => {
  const { title, items } = attributes ?? {};

  return (
    <>
      <h4 className={css.collectionTitle}>{title}</h4>
      <ul className={css.collectionItemsList}>
        {items?.map((item, index) => {
          if (item?.__typename === 'ComponentNavProduct') {
            return <Product key={item.id} {...item} />;
          }
        })}
      </ul>
    </>
  );
};

interface ProductSectionProps {
  section: ComponentNavNavSection;
}

const ProductSection: FC<ProductSectionProps> = ({ section }) => {
  return (
    <div>
      {section.collections?.data.map((collection) => {
        return <Collection key={collection.id} {...collection} />;
      })}
    </div>
  );
};

interface SmallMenuProps {
  logo: Navbar['logo'];
  items: Navbar['items'];
  isOpen: boolean;
  closeMenu: MouseEventHandler;
  accentColorFilter: string;
  accentColor: string;
}

const SmallMenu: FC<SmallMenuProps> = ({
  logo,
  items,
  isOpen,
  closeMenu,
  accentColorFilter,
  accentColor,
}) => {
  const [selectedSection, setSelectedSection] =
    useState<ComponentNavNavSection | null>(null);

  const mappedItems = useMemo(
    () =>
      items.map((item) => {
        if (
          item === null ||
          item.__typename === 'Error' ||
          typeof item.__typename === 'undefined'
        ) {
          return;
        }

        const Component = navbarItemsMap[item.__typename];
        return (
          <Component
            {...item}
            // @ts-expect-error
            key={item.id}
            selectSection={setSelectedSection}
            className={cn({ [css.hasSelectedSection]: !!selectedSection })}
            accentColor={accentColor}
          />
        );
      }),
    [items, setSelectedSection, selectedSection, accentColor],
  );

  return (
    <div className={cn(css.smallMenuContainer, { [css.active]: isOpen })}>
      <div className={cn(css.smallMenu, { [css.active]: isOpen })}>
        <div className={css.header}>
          <div
            className={cn(css.logoContainer, {
              [css.hasSelectedSection]: !!selectedSection,
            })}
          >
            <Logo
              logo={logo}
              heightModifier={4.5}
              className={css.logo}
              style={{ filter: accentColorFilter }}
            />
            <button
              onClick={() => setSelectedSection(null)}
              className={css.backButton}
            >
              <span className={css.materialIcons} />
            </button>
          </div>
          <div className={css.closeButtonContainer}>
            <button
              onClick={closeMenu}
              className={cn(css.materialIcons, css.closeButton)}
            ></button>
          </div>
        </div>
        <div className={css.menuContainer}>
          <ul
            className={cn(css.mainMenu, {
              [css.hasSelectedSection]: !!selectedSection,
            })}
          >
            {mappedItems}
          </ul>
          <div
            className={cn(css.productSectionsMenu, {
              [css.hasSelectedSection]: !!selectedSection,
            })}
          >
            {selectedSection && <ProductSection section={selectedSection} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallMenu;

'use client';

import Logo from 'components/shared/nav/Logo';
import Link from 'next/link';

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import css from 'styles/Navbar.module.scss';

import {
  ComponentElementsLink,
  type Navbar,
  NavbarItemsDynamicZone,
} from '__generated__/schema.graphql.types';
import { MappedDynamicZone } from 'types/dynamiczone';

import ProductMenu from 'components/shared/nav/ProductMenu';
import SmallMenu from 'components/shared/nav/SmallMenu';
import { FilterColor, Solver } from 'lib/FilterColor';
import hexRgb from 'hex-rgb';

const NavbarLink: FC<ComponentElementsLink> = ({ url, label, id }) => {
  return (
    <li className={css.label} key={id}>
      <Link href={url ?? ''}>{label}</Link>
    </li>
  );
};

const navbarItemsMap: MappedDynamicZone<NavbarItemsDynamicZone> = {
  ComponentElementsLink: NavbarLink,
  // @ts-expect-error
  ComponentNavProductMenu: ProductMenu,
};

interface NavbarProps extends Navbar {
  accentColorFilter: string;
  accentColor: string;
}

const Navbar: FC<NavbarProps> = ({
  logo,
  items,
  accentColorFilter,
  accentColor: ssrAccentColor,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const navRef = useRef<HTMLElement>(null);

  const [filters, setFilters] = useState<string>(accentColorFilter);
  const [accentColor, setAccentColor] = useState<string>(ssrAccentColor);
  useEffect(() => {
    if (navRef.current) {
      const clientAccentColor = (
        getComputedStyle(navRef.current).getPropertyValue(
          '--nav-accent-color',
        ) || '#32b300'
      ).trim();
      const filterColor = hexRgb(clientAccentColor);
      const solver = new Solver(
        new FilterColor(filterColor.red, filterColor.green, filterColor.blue),
      );
      const filter = solver.solve().filter;

      setAccentColor(clientAccentColor);
      setFilters(filter);
    }
  }, []);

  const mappedNavItems = useMemo(
    () =>
      items.map((item) => {
        if (
          item === null ||
          item.__typename === 'Error' ||
          typeof item.__typename === 'undefined'
        ) {
          return;
        }

        const Component = navbarItemsMap[item.__typename];
        return (
          <Component
            {...item}
            // @ts-expect-error
            key={item.id}
            // @ts-expect-error
            listRef={listRef}
            navRef={navRef}
          />
        );
      }),
    [items],
  );

  const [smallMenuOpen, setSmallMenuOpen] = useState(false);

  return (
    <nav className={css.nav} ref={navRef}>
      <div className={css.container}>
        <Logo logo={logo} style={{ filter: filters }} />
        <ul className={css.menu} ref={listRef}>
          {mappedNavItems}
        </ul>
        <div className={css.smallMenuNav}>
          <button
            className={css.smallMenuButton}
            onClick={() => setSmallMenuOpen(!smallMenuOpen)}
          >
            <div className={css.materialIcons}></div>
            <style jsx>{`
              div:after {
                content: 'menu';
              }
            `}</style>
          </button>
        </div>

        <SmallMenu
          logo={logo}
          items={items}
          closeMenu={() => setSmallMenuOpen(false)}
          isOpen={smallMenuOpen}
          accentColorFilter={filters}
          accentColor={accentColor}
        />
      </div>
    </nav>
  );
};

export default Navbar;

'use client';

import StrapiImage from 'components/shared/Image';
import Link from 'next/link';

import { CSSProperties, FC, RefObject, useRef, useState } from 'react';
import css from 'styles/ProductMenu.module.scss';
import cn from 'classnames';

import {
  ComponentNavProduct,
  ComponentNavProductMenu,
  ComponentNavProductVariant,
  ProductCollectionItemsDynamicZone,
} from '__generated__/schema.graphql.types';
import { MappedDynamicZone } from 'types/dynamiczone';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';
import Markdown from 'components/elements/Markdown';

interface ProductProps extends ComponentNavProduct {
  style: CSSProperties;
  className: string;
}

const Product: FC<ProductProps> = ({ product, style, className }) => {
  const attributes = product!.data?.attributes;

  const page = attributes?.page;
  const icon = attributes?.icon;
  const color = attributes?.accentColor;
  const description = attributes?.description;
  const name = attributes?.name;
  const duration = attributes?.duration
  const ageRange = attributes?.ageRange

  return (
    <li
      style={style}
      className={cn(css.collectionItem, className)}
      key={product!.data?.id}
    >
      <Link href={'/' + page?.data?.attributes?.slug}>
        <div className={css.iconContainer}>
          {icon && <StrapiImage strapi={icon} fill sizes="100vw" />}
        </div>
        <h4 className={css.title} style={{ color: color ?? '' }}>{name}</h4>
        <div className={css.description}>
          <p>{description}</p>
          <div className={css.chips}>
            {[ageRange, duration].map(chip => {
              return chip && <span key={chip} className={css.chip}>{chip}</span>
            })}
          </div>
        </div>
      </Link>
    </li>
  );
};

interface ProductVariantProps extends ComponentNavProductVariant {
  style: CSSProperties;
  className: string;
}

const ProductVariant: FC<ProductVariantProps> = ({
  productVariant,
  style,
  className,
}) => {
  const attributes = productVariant!.data?.attributes;
  const product = attributes?.product;
  const productAttributes = product?.data?.attributes;
  const name = attributes?.name;
  const description = attributes?.description;
  const icon = attributes?.icon;
  const page = productAttributes?.page;
  const color = productAttributes?.accentColor ?? 'inherit';

  return (
    <li
      style={style}
      className={cn(css.collectionItem, className)}
      key={productVariant?.data?.id}
    >
      <Link href={'/' + page?.data?.attributes?.slug}>
        <div className={css.iconContainer}>
          {icon && <StrapiImage strapi={icon} fill sizes="100vw" />}
        </div>
        <h4 style={{ color }}>{name}</h4>
        <p>{description}</p>
      </Link>
    </li>
  );
};

const productCollectionMap: MappedDynamicZone<ProductCollectionItemsDynamicZone> =
  {
    // @ts-expect-error
    ComponentNavProduct: Product,
    // @ts-expect-error
    ComponentNavProductVariant: ProductVariant,
  };

interface ProductMenuProps extends ComponentNavProductMenu {
  listRef: RefObject<HTMLUListElement>;
  navRef: RefObject<HTMLElement>;
}

const isWithinBounds = (coords: { x: number; y: number }, rect: DOMRect) => {
  const { x, y } = coords;
  const { left, right, top, bottom } = rect;
  const { pageYOffset } = window;
  const adjustedTop = top + pageYOffset;
  const adjustedBottom = bottom + pageYOffset;

  // The offset is used as a grace area so that accidentally
  // moving the cursor outside the bounds would not immediately close the window.
  const offset = 16;
  if (x >= left - offset && x <= right + offset) {
    if (y >= adjustedTop - offset && y <= adjustedBottom + offset) {
      return true;
    }
  }

  return false;
};

const ProductMenu: FC<ProductMenuProps> = ({
  sections,
  label,
  listRef,
  navRef,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const labelRef = useRef<HTMLSpanElement>(null);
  const hoverMenuRef = useRef<HTMLDivElement>(null);
  const xOffset = useRef(0);

  const handleResize = () => {
    updateXOffset();
  };

  const handleMouseMove = ({ pageX, pageY }: MouseEvent) => {
    if (isOpen === false) return;

    if (navRef.current == null) {
      return;
    }

    if (hoverMenuRef.current == null) {
      return;
    }

    const padding = 16 * 1.5;
    const navBounds = navRef.current.getBoundingClientRect();
    const paddedNavBounds: DOMRect = {
      ...navBounds,
      left: navBounds.left - padding,
      top: navBounds.top - padding,
      right: navBounds.right + padding,
      bottom: navBounds.bottom + padding,
    };

    const isInNav = isWithinBounds({ x: pageX, y: pageY }, paddedNavBounds);

    const hoverMenuBounds = hoverMenuRef.current.getBoundingClientRect();
    const paddedHoverMenuBounds: DOMRect = {
      ...hoverMenuBounds,
      left: hoverMenuBounds.left - padding,
      top: hoverMenuBounds.top - padding,
      right: hoverMenuBounds.right + padding,
      bottom: hoverMenuBounds.bottom + padding,
    };

    const isInHoverMenu = isWithinBounds(
      { x: pageX, y: pageY },
      paddedHoverMenuBounds,
    );

    if (isInNav === false && isInHoverMenu === false) {
      setIsOpen(false);
    }
  };

  useEventListener('resize', handleResize);
  useEventListener('mousemove', handleMouseMove);
  useOnClickOutside(hoverMenuRef, () => setIsOpen(false));

  const updateXOffset = () => {
    if (labelRef.current && listRef.current) {
      const { right, width } = labelRef.current.getBoundingClientRect();
      const { right: listRight } = listRef.current.getBoundingClientRect();
      xOffset.current = Math.floor(listRight - right + width / 2);
    }
  };

  const handleHoverStart = () => {
    updateXOffset();
    setIsOpen(true);
  };

  const sectionMenuItems = sections!.map((section, index) => {
    const title = section?.title;
    const description = section?.description;
    const id = section?.id;

    return (
      <li
        key={id}
        className={cn(css.sectionMenuItem, {
          [css.activeSection]: index === currentSection,
        })}
      >
        <button
          onMouseEnter={() => setCurrentSection(index)}
          onClick={() => setCurrentSection(index)}
        >
          <h4>{title}</h4>
          {description && <Markdown content={description} />}
        </button>
      </li>
    );
  });

  const sectionCollections = sections!.map((section) => {
    if (section === null || typeof section.collections === 'undefined') {
      return;
    }

    const { collections } = section;

    const collectionElements = collections!.data.map((collection, index) => {
      if (typeof collection.attributes == 'undefined') {
        return;
      }

      const title = collection.attributes?.title;
      const items = collection.attributes?.items;

      const listItems = items!.map((item) => {
        if (
          item === null ||
          typeof item === 'undefined' ||
          typeof item?.__typename === 'undefined'
        ) {
          return;
        }

        // @ts-expect-error
        const Component = productCollectionMap[item.__typename];
        return (
          <Component
            // @ts-expect-error
            key={item.id}
            className={cn({ [css.firstColumn]: index === 0 })}
            {...item}
          />
        );
      });

      return (
        <li
          key={`${section.id}-${collection.id}`}
          className={cn({ [css.firstColumn]: index === 0 })}
        >
          <h4>{title}</h4>
          <ul className={css.collectionItemsList}>{listItems}</ul>
        </li>
      );
    });

    return (
      <ul
        key={`collections-section-${section.id}`}
        className={css.sectionCollections}
      >
        {collectionElements}
      </ul>
    );
  });

  return (
    <li className={css.productMenu} key={`productMenu-${id}`}>
      <span
        ref={labelRef}
        onMouseEnter={handleHoverStart}
        className={cn(css.label, { [css.active]: isOpen })}
      >
        {label}
      </span>
      <div className={cn(css.hoverMenuContainer, { [css.active]: isOpen })}>
        <div
          ref={hoverMenuRef}
          className={cn(css.hoverMenu, { [css.active]: isOpen })}
        >
          <ul className={css.sectionMenu}>{sectionMenuItems}</ul>
          <div>{sectionCollections[currentSection]}</div>
        </div>
        <style jsx>{`
          div:before {
            --offset: ${xOffset.current}px;
          }
        `}</style>
      </div>
    </li>
  );
};

export default ProductMenu
